<template>
  <div>

        <img :src="image" style="width: 100%; height: 650px;" />


  </div>
</template>
<script>
const images = [
  "images/09/muslim-bg-1.png",
  "images/09/muslim-bg-2.png",

];
export default {
  name: "ImageSlider",
  data() {
    return {index: 0, image: images[0]};
  },
  methods: {
    next() {
      this.index = (this.index + 1) % images.length;
      this.image = images[this.index];
    },
    autoChangeSlide() {
      setInterval(() => {
        this.next();
      }, 2500);
    },
  },
  beforeMount() {
    this.autoChangeSlide();
  },
};
</script>