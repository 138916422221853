import {createApp} from 'vue'
import '@/assets/css/bootstrap.min.css'
import '@/assets/css/font-awesome.min.css'
import '@/assets/css/theme-style.css'
import '@/assets/style.css'
import '@/assets/css/masterslider.main.css'


import App from './App.vue'
import router from './router'
import store from './store'

createApp(App).use(store).use(router).mount('#app')
