<template>
  <section class="contact-us-section">
    <div class="container">
      <div class="content-section">
        <div class="content-wrapper">
          <h2 class="section-title">Send Us a Message</h2>
          <div class="section-detail"> You can send us a message or enquiry
          </div>
          <!-- /.section-detail -->
          <div role="form" class="wpcf7" id="wpcf7-f7-p367-o1" lang="en-US" dir="ltr">
            <div class="screen-reader-response"></div>
            <form action="#" method="post" class="wpcf7-form" novalidate="novalidate">
              <div style="display: none;">
                <input type="hidden" name="_wpcf7" value="7"/>
                <input type="hidden" name="_wpcf7_version" value="4.2.2"/>
                <input type="hidden" name="_wpcf7_locale" value="en_US"/>
                <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f7-p367-o1"/>
                <input type="hidden" name="_wpnonce" value="bfbea92b91"/>
              </div>
              <!-- end styel-->
              <div class="col-md-6 form-group">
                <label class="sr-only">Full Name</label>
                <span class="wpcf7-form-control-wrap your-name">
                              <input type="text" name="your-name" value="" size="40"
                                     class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                                     aria-required="true" aria-invalid="false" placeholder="Full Name"/>
                              </span>
              </div>
              <!-- end col md 6-->
              <div class="col-md-6 form-group">
                <label class="sr-only">Email Address</label>
                <span class="wpcf7-form-control-wrap your-email">
                              <input type="email" name="your-email" value="" size="40"
                                     class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email form-control"
                                     aria-required="true" aria-invalid="false" placeholder="Email Address"/>
                              </span>
              </div>
              <!-- end col md 6-->
              <div class="form-group col-md-12">
                <label class="sr-only">Subject</label>
                <span class="wpcf7-form-control-wrap your-subject">
                              <input type="text" name="your-subject" value="" size="40"
                                     class="wpcf7-form-control wpcf7-text form-control" aria-invalid="false"
                                     placeholder="Subject"/>
                              </span>
              </div>
              <!-- end col md 6-->
              <div class="form-group col-md-12">
                                    <span class="wpcf7-form-control-wrap your-message">
                              <textarea name="your-message" cols="40" rows="5"
                                        class="wpcf7-form-control wpcf7-textarea form-control input-md"
                                        aria-invalid="false" placeholder="YOUR MESSAGE"></textarea>
                              </span>
              </div>
              <!-- end col md 6-->
              <p class="event-btn-container col-md-12">
                <input type="submit" value="SEND NOW"
                       class="wpcf7-form-control wpcf7-submit btn custom-btn"/>
              </p>
              <div class="wpcf7-response-output wpcf7-display-none"></div>
            </form>
          </div>
          <!-- end wpcf7-->
        </div>
        <!-- end content wrapper-->
      </div>
      <!-- end content section-->
    </div>
    <!-- end container-->
  </section>
</template>

<script>
export default {
  name: "ContactUs"
}
</script>

<style scoped>

</style>