<template>
  <div>
    <div class="footer-widget">
      <div class="container">
        <aside id="text-2" class="widget widget_text">
          <div class="col-md-12">
            <h2 class="widget-title"> Nasarawa Central Mosque Foundation</h2>
            <div class="textwidget">

              The Nasarawa Central Mosque Foundation Project aims to publish the progress of the mosque construction
              project and also receive voluntary donations from donors towards the completion of the mosque.
            </div>
          </div>
          <!-- end col md 12 -->
        </aside>


      </div>
      <!-- end container -->
    </div>
    <!-- end footer widget -->
    <!-- start footer -->
    <footer id="footer">
      <div class="container">
        <div class="row">
          <div class="logo text-center">
            <a href=""><img src="@/assets/images/logo2.jpg" style="width:160px; height:90px;"
                            alt="Nasarawa Central Mosque Foundation">
            </a>
          </div>
          <!-- /.logo -->
          <div class="copyright">
            <p>Copyright &copy; 2017 - {{state.theYear}}  <a href="http://ncmosquefoundation.com">Nasarawa Central Mosque
              Foundation</a>
            </p>
          </div>
          <!-- .copyright -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container -->
    </footer>
  </div>
</template>

<script>
import store from "../store";

export default {
  name: "Footer",

  setup() {
    const state = store.state


    return {state}

  }
}
</script>
