<template>
<div>
  <div id="preloader">
    <div id="loader">
      <div class="loader-inner ball-scale-multiple">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <!-- loader inner -->
    </div>
    <!-- /#loader -->
  </div>
  <!-- /#preloader -->
  <!-- peace layout start. -->
  <div id="peace-layout">

    <Header/>


    <!-- MasterSlider -->
    <ImageSlider />

    <!-- END MasterSlider -->

    <!-- END MasterSlider -->

    <!-- piller of islam section start  -->
    <section class="piller-of-islam section-padding">
      <div class="container">
        <div class="row">
          <h2 class="section-title">Pillars Of Islam</h2>
          <div class="section-detail">
          </div>
          <!-- /.section-detail -->
          <div class="col-md-10 centered">
            <div class="piler-of-islam">
              <div class="col-5">
                <div class="item">
                  <div class="item-element">
                    <span class="flaticon-book102"></span>
                    <div class="text">Kalma</div>
                  </div>
                  <!-- end item element -->
                </div>
                <!-- end item -->
              </div>
              <!-- /.col-md-3 -->
              <div class="col-5">
                <div class="item">
                  <div class="item-element">
                    <span class="flaticon-evening"></span>
                    <div class="text">
                      Salat
                    </div>
                  </div>
                  <!-- end item element -->
                </div>
                <!-- end item -->
              </div>
              <!-- /.col-md-3 -->
              <div class="col-5">
                <div class="item">
                  <div class="item-element">
                    <span class="flaticon-moon159"></span>
                    <div class="text">
                      Fasting
                    </div>
                  </div>
                  <!-- end item element -->
                </div>
                <!-- end item -->
              </div>
              <!-- /.col-md-3 -->
              <div class="col-5">
                <div class="item">
                  <div class="item-element">
                    <span class="flaticon-mecca"></span>
                    <div class="text">
                      Hajj
                    </div>
                  </div>
                  <!-- end item element -->
                </div>
                <!-- end item -->
              </div>
              <!-- /.col-md-3 -->
              <div class="col-5">
                <div class="item">
                  <div class="item-element">
                    <span class="flaticon-pakistan4"> </span>
                    <div class="text">
                      Zakat
                    </div>
                  </div>
                  <!-- end item element -->
                </div>
                <!-- end item -->
              </div>
              <!-- /.col-md-3 -->
            </div>
            <!-- end piller of islam -->
          </div>
          <!-- end col md 10 -->
        </div>
        <!-- /.row -->
        <div class="curve-line hidden-xs"></div>
      </div>
      <!-- /.container -->
    </section>
    <!-- /#piller-of-islam -->
    <!-- portfolio section start  -->
    <section class="portfolio-section section-padding">

      <!-- /.section-detail -->
      <!-- start portfolio gallery  -->
      <div class="portfolio gallery-section">

        <!-- end container  -->
        <div class="clearfix portfolio-item isotope-items isotope-masonry-items">


          <!-- end item  -->
          <div class="item shab-e-barat effect-oscar">
            <img width="1000" height="750" src="@/assets/images/works/a.jpeg"
                 class="attachment-gallery-thumb wp-post-image" alt="A"/>
            <div class="item-description">
              <div class="item-link">
                <a class="boxer" data-boxer-height="500" data-boxer-width="500"
                   href="/assets/images/works/a.jpeg">
                  <i class="fa plus-sign"></i>
                </a>
              </div>
              <!-- /.item-link -->
            </div>
            <!-- end item description  -->
          </div>
          <!-- end item  -->
          <div class="item ramadan effect-oscar">
            <img width="800" height="559" src="@/assets/images/works/b.jpeg"
                 class="attachment-gallery-thumb wp-post-image" alt="B"/>
            <div class="item-description">
              <div class="item-link">
                <a class="boxer" data-boxer-height="500" data-boxer-width="500"
                   href="../assets/images/09/gallery19.jpg">
                  <i class="fa plus-sign"></i>
                </a>
              </div>
              <!-- /.item-link -->
            </div>
            <!-- end item description  -->
          </div>
          <!-- end item  -->
          <div class="item ramadan effect-oscar">
            <img width="800" height="531" src="@/assets/images/09/gallery16.jpg"
                 class="attachment-gallery-thumb wp-post-image" alt="gallery16"/>
            <div class="item-description">
              <div class="item-link">
                <a class="boxer" data-boxer-height="500" data-boxer-width="500"
                   href="@/assets/images/09/gallery16.jpg">
                  <i class="fa plus-sign"></i>
                </a>
              </div>
              <!-- /.item-link -->
            </div>
            <!-- end item description  -->
          </div>
          <!-- end item  -->
          <div class="item eid-al-adha effect-oscar">
            <img width="800" height="577" src="@/assets/images/09/gallery17.jpg"
                 class="attachment-gallery-thumb wp-post-image" alt="gallery17"/>
            <div class="item-description">
              <div class="item-link">
                <a class="boxer" data-boxer-height="500" data-boxer-width="500"
                   href="@/assets/images/09/gallery17.jpg">
                  <i class="fa plus-sign"></i>
                </a>
              </div>
              <!-- /.item-link -->
            </div>
            <!-- end item description  -->
          </div>
          <!-- end item  -->
          <div class="item eid-al-adha effect-oscar">
            <img width="1000" height="714" src="@/assets/images/09/gallery14.jpg"
                 class="attachment-gallery-thumb wp-post-image" alt="gallery14"/>
            <div class="item-description">
              <div class="item-link">
                <a class="boxer" data-boxer-height="500" data-boxer-width="500"
                   href="@/assets/images/09/gallery14.jpg">
                  <i class="fa plus-sign"></i>
                </a>
              </div>
              <!-- /.item-link -->
            </div>
            <!-- end item description  -->
          </div>
          <!-- end item  -->
          <div class="item eid-al-adha effect-oscar">
            <img width="500" height="319" src="@/assets/images/09/gallery15.jpg"
                 class="attachment-gallery-thumb wp-post-image" alt="gallery15"/>
            <div class="item-description">
              <div class="item-link">
                <a class="boxer" data-boxer-height="500" data-boxer-width="500"
                   href="@/assets/images/09/gallery15.jpg">
                  <i class="fa plus-sign"></i>
                </a>
              </div>
              <!-- /.item-link -->
            </div>
            <!-- end item description  -->
          </div>
          <!-- end item  -->
          <div class="item eid-ul-fitr effect-oscar">
            <img width="1000" height="750" src="@/assets/images/09/gallery11.jpg"
                 class="attachment-gallery-thumb wp-post-image" alt="gallery11"/>
            <div class="item-description">
              <div class="item-link">
                <a class="boxer" data-boxer-height="500" data-boxer-width="500"
                   href="@/assets/images/09/gallery11.jpg">
                  <i class="fa plus-sign"></i>
                </a>
              </div>
              <!-- /.item-link -->
            </div>
            <!-- end item description  -->
          </div>
          <!-- end item  -->
          <div class="item eid-ul-fitr effect-oscar">
            <img width="730" height="509" src="@/assets/images/09/gallery12.jpg"
                 class="attachment-gallery-thumb wp-post-image" alt="gallery12"/>
            <div class="item-description">
              <div class="item-link">
                <a class="boxer" data-boxer-height="500" data-boxer-width="500"
                   href="@/assets/images/09/gallery12.jpg">
                  <i class="fa plus-sign"></i>
                </a>
              </div>
              <!-- /.item-link -->
            </div>
            <!-- end item description  -->
          </div>
          <!-- end item  -->
          <div class="item eid-ul-fitr effect-oscar">
            <img width="800" height="533" src="@/assets/images/09/gallery13.jpg"
                 class="attachment-gallery-thumb wp-post-image" alt="gallery13"/>
            <div class="item-description">
              <div class="item-link">
                <a class="boxer" data-boxer-height="500" data-boxer-width="500"
                   href="@/assets/images/09/gallery13.jpg">
                  <i class="fa plus-sign"></i>
                </a>
              </div>
              <!-- /.item-link -->
            </div>
            <!-- end item description  -->
          </div>
          <!-- end item  -->

        </div>
        <!-- /.gallery-item -->
        <a class="read-more" href="#">View More</a>
      </div>
      <!-- /.gallery-section -->
    </section>
    <!-- gallery section -->

    <section class="shedule section-padding">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <h2 class="section-title">Prayer Schedule</h2>
            <div class="section-detail">
            </div>
            <!-- end section details  -->
          </div>
          <!-- end col-md 4  -->
          <div class="col-md-8">
            <div class="shedule-list col-md-12">
              <div class="shedule-time shedule-before col-md-6 col-xs-12">
                <div class="schedule-name col-xs-7">
                  <h3>Fajr</h3>
                  <span>5:00 AM</span>
                </div>
                <!-- end schedule name -->
                <div class="clock schedule-time col-xs-5" data-time="04:30:00">
                </div>
                <!-- end clock -->
              </div>
              <!-- end schedule time-->
              <div class="shedule-time col-md-6 col-xs-12">
                <div class="schedule-name col-xs-7">
                  <h3>Dhuhr</h3>
                  <span>1:30 PM </span>
                </div>
                <!-- end schedule name -->
                <div class="clock schedule-time col-xs-5" data-time="13:00:00">
                </div>
                <!-- end clock -->
              </div>
              <!-- end schedule time-->
              <div class="shedule-time shedule-before col-md-6 col-xs-12">
                <div class="schedule-name col-xs-7">
                  <h3>Asr</h3>
                  <span>4:30 PM</span>
                </div>
                <!-- end schedule name -->
                <div class="clock schedule-time col-xs-5" data-time="17:00:00">
                </div>
                <!-- end clock -->
              </div>
              <!-- end schedule time-->
              <div class="shedule-time col-md-6 col-xs-12">
                <div class="schedule-name col-xs-7">
                  <h3>Maghrib</h3>
                  <span>6:30 PM</span>
                </div>
                <!-- end schedule name -->
                <div class="clock schedule-time col-xs-5" data-time="18:30:00">
                </div>
                <!-- end clock -->
              </div>
              <!-- end schedule time-->
              <div class="shedule-time shedule-before shedule-after col-md-6 col-xs-12 no-margin">
                <div class="schedule-name col-xs-7">
                  <h3>Isha</h3>
                  <span>8:00 PM</span>
                </div>
                <!-- end schedule name -->
                <div class="clock schedule-time col-xs-5" data-time="20:30:00">
                </div>
                <!-- end clock -->
              </div>
              <!-- end schedule time-->
              <div class="shedule-time shedule-after col-md-6 col-xs-12 no-margin responsive-fix">
                <div class="schedule-name col-xs-7">
                  <h3>Jummah</h3>
                  <span>12:30 PM</span>
                </div>
                <!-- end schedule name -->
                <div class="jummah schedule-time col-xs-5" data-time="12:30:00">
                </div>
                <!-- end clock -->
              </div>
              <!-- end schedule time-->
            </div>
            <!-- /.shedule-list -->
            <div class="sunrise col-md-6">
              <div class="col-md-5">
                <img src="@/assets/images/sun.png" alt="sunrise">
              </div>
              <!-- end col md 5-->
              <div class="col-md-7">
                <h2>SUNRISE</h2>
                <span>5.30 AM</span>
              </div>
              <!-- end col md 7-->
            </div>
            <div class="sunrise sunset col-md-6">
              <div class="col-md-5">
                <img src="@/assets/images/sun2.png" alt="sunset">
              </div>
              <!-- end col md 5-->
              <div class="col-md-7">
                <h2>SUNSET</h2>
                <span>6.20 PM</span>
              </div>
              <!-- end col md 7-->
            </div>
            <!-- end col md 6-->
          </div>
          <!-- end col-md 8  -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container -->
    </section>

    <!-- start full width -->
    <div class="full-width">
      <div class="row-fluid">
        <div class="content-section">
          <div class="content-wrapper">
            <section class="donate section-padding">
              <div class="container">
                <div class="row">
                  <h2 class="section-title">Our Cause</h2>
                  <!-- /.section-title -->

                  <!-- end donate details-->
                  <div class="found">0.01 % Funded <span> / NGN 864,600,000 To Go</span>
                  </div>
                  <!-- end found-->
                  <div class="page-wrap centered">
                    <div class="meter red">
                      <span style="width: 82.5%"></span>
                    </div>
                  </div>
                  <!-- end page wrap-->
                  <br>
                  <p class="event-btn-container col-md-12">
                    <a class="btn custom-btn" href="{% url 'donors:donate' %}">DONATE NOW <i
                        class="fa fa-heart"></i></a>
                  </p>
                </div>
                <!-- /.row -->
              </div>
              <!-- /.container -->
            </section>
            <!-- end section padding-->
          </div>
          <!-- end conent wrapper-->
        </div>
        <!-- end conent section-->
      </div>
      <!-- end row fluid-->
    </div>
    <!-- end full width -->
   <YoutubeEmbed />
    <!-- end section padding -->


    <!-- start contact us -->
    <ContactUs />
    <!-- end contact us section-->
    <div class="full-width">
      <div class="row-fluid">
        <div class="content-section">
          <div class="content-wrapper">
            <div class="map-container">
              <div class="googleMaps google-map-container"></div>
              <div class="contact-info col-md-6 pull-right">
                <div class="contact-person">
                  <h2>Contact Persons</h2>
                  <ContactPerson contact-name="Alh. Hassan Ahmed (Madakin Nasarawa)" contact-phone="+2348133887842" contact-email="info@ncmosquefoundation.com" position="Chairman" />
                  <ContactPerson contact-name=" ESV. Adamu Kasimu, FNIVS" contact-phone="+2348033121013" position="Secretary" />
                  <ContactPerson contact-name="Mal. Sulayman Aliyu Abdullahi" contact-phone="+2348054529543" position="Arabic Contact" />
                  <ContactPerson contact-name="Alh Isa Ahmed (Muqqaddamin Nasarawa)" contact-phone="+2348055241947" position="Treasurer" />
                  <ContactPerson contact-name="Umar Aliyu Abdullah" contact-phone="+2347065658384" position="Support Personnel" />

                  <!-- /.info -->
                </div>
                <!-- /.contact-person -->
                <div class="contact-information">
                  <h2>Contact Info</h2>
                  <div class="address">
                    <div class="media">
                      <div class="media-left"><i class="fa fa-map-marker base-color"></i>
                      </div>
                      <div class="media-body">Nasarawa Central Mosque.
                        <br/> Nasarawa LG, Nasarawa State.
                        <br>
                      </div>
                    </div>
                    <!-- end media-->

                  </div>
                  <!-- /.info -->
                </div>
                <!-- /.contact-person -->
              </div>
              <!-- end contact info -->
            </div>
            <!-- Google Map Section End -->
          </div>
          <!-- end content wrapper-->
        </div>
        <!-- end content section-->
      </div>
      <!-- end row fluid-->
    </div>
    <!-- end full width -->
    <div id="location">
      <div class="container">
        <div class="row">
          <div class="social-icons">
<!--            <ul>-->
<!--              <li class="facebook"><a href="http://facebook.com/id"><i class="fa fa-facebook"></i><span>Facebook</span></a>-->
<!--              </li>-->
<!--              <li class="twitter"><a href="http://twitter.com/id"><i class="fa fa-twitter"></i><span>Twitter</span></a>-->
<!--              </li>-->

<!--            </ul>-->
          </div>
          <!-- end social icon -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container -->
    </div>
    <!-- end loacation -->
    <!-- footer widget start -->
    <Footer/>

    <!-- end footer -->
  </div>
  <!-- /.peace layout end -->
  </div>
</template>

<script>
// @ is an alias to /src

import Footer from "../components/Footer";
import Header from "../components/Header";
import YoutubeEmbed from "../components/YoutubeEmbed";
import ContactUs from "../components/ContactUs";
import ImageSlider from "../components/ImageSlider";
import ContactPerson from "../components/ContactPerson";

export default {
  name: 'Home',
  components: {ContactPerson, ImageSlider, Header, Footer, YoutubeEmbed, ContactUs},

}


</script>
