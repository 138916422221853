<template>
  <section class="section-padding">
    <div class="container">
      <div class="content-section">
        <div class="content-wrapper">
          <h2 class="section-title"> Proposed Nasarawa Central Mosque Video</h2>

          <!-- /.section-detail -->
          <div class="vc_row wpb_row vc_inner row-fluid">
            <div class="col-md-offset-2 col-sm-8 col-md-offset-2">
              <div class="content-wrapper">
                <div class="peace-blog">
                  <div class="post-list-container">
                    <div class="scrollbar-inner">


                      <iframe width="760" height="415" src="https://www.youtube.com/embed/XZlMx4_iZQo" frameborder="0"
                              allow="autoplay; encrypted-media" allowfullscreen></iframe>

                    </div>
                    <!-- end scrollbar inner-->
                  </div>
                  <!-- end post list container-->
                </div>
                <!-- end peace blog-->
              </div>
              <!-- end content wrapper-->
            </div>
            <!-- end col sm 8-->

          </div>
          <!-- end vc row-->
        </div>
        <!-- end content wrapper-->
      </div>
      <!-- end content section-->
    </div>
    <!-- end container-->
  </section>
</template>

<script>
export default {
  name: "YoutubeEmbed",

}
</script>

<style scoped>

</style>