<template>
  <header id="top-section">
    <div class="container">
      <div class="col-sm-4 hidden-xs address">
        <div class="pull-left">
          <ul>
            <li><i class="fa fa-phone"></i>+2348033174042</li>
            <li><i class="fa fa-envelope-o"></i> info@ncmosquefoundation.com</li>
          </ul>
        </div>
        <!-- pull left  -->
      </div>
      <!-- /.address -->
      <div class="col-sm-4 col-xs-12">
        <div class="logo text-center">
       <router-link to="/" >
            <img style="width:160px; height:120px;" src="@/assets/images/logo2.jpg"
                 alt="Nasarawa Central Mosque Foundation"/> </router-link>
        </div>
        <!-- /.logo -->
      </div>
      <!-- col sm 4 -->
      <div class="social-search pull-right hidden-xs">
        <ul>
          <li><a target="_blank" href="https://www.facebook.com/ncmosquefoundation/"><i class="fa fa-facebook"></i><span></span></a>
          </li>


        </ul>


      </div>
      <!-- social search  -->
    </div>
    <!-- /.container -->
    <!-- menu slider start -->
    <div class="menu-slider">
      <nav id="peace-menu">
        <div class="container">
          <!-- Brand and toggle get grouped for better mobile display -->
          <div class="navbar-header">
            <button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
                    data-target="#responsive-icon" aria-expanded="false">
              <span class="sr-only">Toggle navigation</span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
          </div>
          <!-- end navbar head -->
          <!-- Collect the nav links, forms, and other content for toggling -->
          <div class="collapse navbar-collapse" id="responsive-icon">
            <div class="menu-menu-1-container">
              <ul class="nav navbar-nav text-center nav navbar-nav text-center">
                <li class="dropdown active">
                  <router-link to="/"  title="Home"> Home </router-link>
                </li>


                <li class="dropdown">
                  <router-link to="/"  title="Gallery"> Gallery </router-link>
                </li>
                <li>

                  <router-link to="/"  title="Contact Us"> Contact Us </router-link>
                </li>
                <li class="button-skew"><a class="donate-button" href="{% url 'donors:donate' %}"><span> Donate Now <i
                    class="fa fa-heart"></i></span></a>
                </li>
              </ul>
            </div>
            <!-- end menu-1-container -->
          </div>
          <!-- /.navbar-collapse -->
        </div>
        <!-- end container -->
      </nav>
      <!-- /#peace-menu -->
    </div>
    <!-- /.menu-slider -->
  </header>
  <!--  /# top section  -->
</template>

<script>
export default {
  name: "Header"
}
</script>
