<template>
  <div class="row top">
    <img width="75" height="75"
         src="@/assets/images/09/gallery20-150x150.jpg"
         class="img-circle" alt="team-3"/>
    <div class="info">
      <h3>{{ contactName }}</h3>
      <p>{{ position }}</p>
      <span><b>Phone:</b> <a :href="`tel:${contactPhone}`" class="phone">{{ contactPhone }}</a></span>
      <p v-if="contactEmail"><b>Email:</b> {{ contactEmail }} </p>
    </div>
    <p></p>
  </div>
</template>

<script>
export default {
  name: "ContactPerson",
  props: {
    contactName: {
      type: String,
      required: true
    },
    contactPhone: {
      type: String,
      required: true
    },
    contactEmail: {
      type: String,
      required: true
    },
    position: {
      type: String,
      required: false
    }

  }
}
</script>

<style scoped>
.phone{
  color: white;
}

</style>