import { createStore } from 'vuex'

export default createStore({
  state: {
    theYear:  new Date().getFullYear()
  },

  //mutations are functions that effect state
  mutations: {

  },

  //actions are functions you call through out the application that call mutation
  actions: {

  },
  modules: {
  }
})
